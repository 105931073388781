import { useCataloguesStore } from "@multicines/stores";
import { SNACKS_VENDOR } from "@multicines/utils";
import ErrorSVG from "images/product-error.svg";
import React from "react";
import { useQueryClient } from "react-query";

import Styles from "./ProductError.styles";
import { ProductErrorProps as Props } from "./ProductError.types";
import ErrorState from "components/global/ErrorState/ErrorState";
import CONSTANTS from "config/constants";
import { useStoresStore } from "stores/stores/stores.store";

const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;

const ProductError: React.FC<Props> = props => {
  const queryClient = useQueryClient();
  const selectedCatalogueId = useCataloguesStore(
    state => state.selectedCatalogue.catalogueId
  );
  const selectedStore = useStoresStore(store => store.selectedStore);
  const [, snacksStore] = selectedStore ?? [];
  const { storeId } = snacksStore ?? {};
  const { vendor: snackVendor } = snacksStore ?? {};
  const { id: selectedSnackVendorId } = snackVendor ?? {};
  const config = {
    catalogueId: selectedCatalogueId,
    storeId: storeId ?? 0,
    vendorId: selectedSnackVendorId ?? SNACKS_VENDOR.id,
    withCategories: true
  };

  const refetchProductsDetailsQuery = () => {
    queryClient.refetchQueries(["products", config]);
  };

  const clickHandler = () => {
    if (WITH_PRODUCT_MODAL) {
      refetchProductsDetailsQuery();
    } else {
      if (typeof window === "undefined") return;
      window.location.reload();
    }
  };

  return (
    <Styles className="ProductError" isModal={WITH_PRODUCT_MODAL}>
      <ErrorState
        icon={<ErrorSVG />}
        title="Hubo un error al cargar el producto"
        description="Intenta recargar la página para poder visualizarlo"
        buttonTitle="Explorar más productos"
        onClick={clickHandler}
      />
    </Styles>
  );
};

ProductError.defaultProps = {};

export default ProductError;
