import { buildArtisnHeaders } from "@multicines/services";
import { useCountriesStore } from "@multicines/stores";
import { useProductsStore } from "@multicines/stores";
import { useRestrictedBySpecialCard } from "@multicines/stores";
import { useShoppingCartStore } from "@multicines/stores";
import { events } from "artisn/analytics";
import { applyBenefit } from "artisn/shopping-cart";
import { Benefit, CartProduct } from "artisn/types";
import { ShareButton, Clickable } from "artisn-ui-react";
import HeartSVG from "images/black-heart.svg";
import FilledHeartSVG from "images/heart-primary-filled.svg";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useRef, useState } from "react";

import Styles from "./ProductMain.styles";
import { ProductMainProps as Props } from "./ProductMain.types";
import ProductPlaceholder from "../Product/Product.placeholder";
import ProductDetailsImage from "../ProductDetailsImage/ProductDetailsImage";
import ProductError from "../ProductError/ProductError";
import ProductErrorMessage from "../ProductErrorMessage/ProductErrorMessage";
import Counter from "components/global/Counter/Counter";
import InfoMessage from "components/global/InfoMessage/InfoMessage";
import ModifiersForm from "components/global/modifiers/ModifiersForm/ModifiersForm";
import AddToCartButton from "components/products/AddToCartButton/AddToCartButton";
import { useAddToWishlist } from "components/products/AddToWishlistButton/AddToWishListButton.hooks";
import AddToWishlistButton from "components/products/AddToWishlistButton/AddToWishlistButton";
import { notify } from "config/bugsnag.config";
import CONSTANTS from "config/constants";
import useAnalytics from "hooks/analytics/useAnalytics";
import useI18n from "hooks/useI18n";
import useOnUnmount from "hooks/useOnUnmount";
import { useProductForm } from "hooks/useProductForm";
import useShippingCost from "hooks/useShippingCost";
import useTalkShop from "hooks/useTalkShop";
import { useAuthStore } from "stores/auth/auth.store";
import { useStoresStore } from "stores/stores/stores.store";
import { getBenefitProductId } from "utils/common.utils";
import { getFullPath, scrollToElement } from "utils/common.utils";
import { createAddToCartNotification } from "utils/notifications.utils";
import { createErrorNotification } from "utils/notifications.utils";
import { createAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToWishlistNotification } from "utils/notifications.utils";
import { dismissAddToCartNotification } from "utils/notifications.utils";

const { WITH_SHARE_PRODUCT, WITH_CART_DRAWER } = CONSTANTS.FEATURE_FLAGS;
const { WITH_WISHLIST, WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;
const { WITH_PURCHASE } = CONSTANTS.FEATURE_FLAGS;
const { SHOPPING_CART_WISHLIST_NAME } = CONSTANTS.ARTISN;
const { API_URL } = CONSTANTS.API;
const { logViewProductDetails } = events.product;

const enhancedConfig = {
  shoppingCartName: SHOPPING_CART_WISHLIST_NAME
};

const ProductMain: React.FC<Props> = props => {
  const { productId, product, isLoading, isError, isSignedIn } = props;
  const { onCloseModal, className, showShoppingCartDrawer } = props;
  const { isModal = false } = props;
  const router = useRouter();
  const isAnonymous = useAuthStore(state => state.isAnonymous);
  const { isTalkShop, navigateWithTalkShop } = useTalkShop();
  const analyticsHook = useAnalytics({
    isSnackWorkflow: true,
    isTalkShop
  });
  const selectedProduct = useProductsStore(store => store.selectedProduct);
  const setSelectedProduct = useProductsStore(
    store => store.setSelectedProduct
  );
  const form = useProductForm(product);
  const selectedStore = useStoresStore(store => store.selectedStore);
  const shippingCost = useShippingCost();
  const selectedCity = useCountriesStore(state => state.selectedCity);
  const { onClick: addToWishlist, inCart } = useAddToWishlist({
    product,
    config: enhancedConfig
  });
  const { additionalInfo } = product ?? {};
  const { specialTicketType } = additionalInfo ?? {};
  const { isRestricted = true } = useRestrictedBySpecialCard(specialTicketType);
  const [showNotification, setShowNotification] = useState(false);
  const [disable, setDisable] = useState(false);
  const { amount: selectedProductAmount } = (product as CartProduct) ?? {};
  const [amount, setAmount] = useState(selectedProductAmount ?? 1);
  const viewProductDetailsLogged = useRef(false);
  const productRef = useRef(product);
  const selectedProductId = product?.productId;
  const ref = useRef(selectedProductId);
  const t = useI18n().cart;

  const { commonParams, canBeSendAnalytics } = analyticsHook;

  const [, snacksStore] = selectedStore ?? [];

  const { images, categories } = product ?? {};
  const { available } = product ?? {};
  const [category] = categories ?? [];
  const { categoryId, name: categoryName } = category ?? {};

  const icon = inCart ? <FilledHeartSVG /> : <HeartSVG />;
  const shoppingCart = useShoppingCartStore(store => store.shoppingCart);
  const temporalBenefit = useShoppingCartStore(store => store.temporalBenefit);
  const setTemporalBenefit = useShoppingCartStore(
    store => store.setTemporalBenefit
  );

  const { benefits } = shoppingCart ?? {};
  const selectedBenefit = benefits ? benefits[0] : undefined;
  const benefitProductId = getBenefitProductId(
    temporalBenefit,
    selectedBenefit
  );
  const isBenefit = benefitProductId === selectedProductId;

  const disableCounter = !available || isRestricted;
  const disableAddToCart = disableCounter || !product?.attributes?.showInMenu;
  const isDinners =
    !!specialTicketType &&
    specialTicketType?.toLowerCase()?.trim() === "diners";

  const goToCategories = () => {
    router.push(`/categories/${categoryId}/${categoryName}`);
  };

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      addToWishlist();
      dismissAddToWishlistNotification();
    },
    [addToWishlist]
  );

  const applyBenefitHandler = async (temporalBenefit: Benefit) => {
    try {
      await applyBenefit(
        {
          benefitId: temporalBenefit.benefitId,
          product,
          shippingCost,
          anonymous: isAnonymous!,
          productConfig: {
            amount: 1,
            // @ts-ignore Now the vendor id is a string
            store: snacksStore
          },
          apiURL: API_URL
        },
        await buildArtisnHeaders()
      );
      setTemporalBenefit(undefined);
    } catch (e) {
      notify(e, "applyBenefit - ProductMain");
      createErrorNotification(e.message, "Cupones");
    }
  };

  const addToCartFinishHandler = async () => {
    if (isTalkShop) {
      navigateWithTalkShop("/categories");
      return;
    }

    if (WITH_CART_DRAWER && !!selectedProduct) {
      if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();
      router.push("/categories");
    }

    if (onCloseModal && WITH_PRODUCT_MODAL) onCloseModal();

    if (WITH_CART_DRAWER && !selectedProduct) {
      dismissAddToCartNotification();
      if (temporalBenefit) await applyBenefitHandler(temporalBenefit);
      createAddToCartNotification(product, showShoppingCartDrawer);
      router.replace("/categories");
      return;
    }

    if (WITH_CART_DRAWER || !selectedCity || !selectedStore) return;

    if (temporalBenefit) {
      await applyBenefitHandler(temporalBenefit);
      router.replace(
        `/cart?cityId=${selectedCity.id}&storeId=${selectedStore[0]?.storeId}`
      );
      return;
    }

    router.push(
      `/cart?cityId=${selectedCity.id}&storeId=${selectedStore[0]?.storeId}`
    );
  };

  const errorHandler = () => {
    if (!form) return;
    const errorGroup = form.renderer.find(group => group.status !== "OK");
    if (!errorGroup) return;
    scrollToElement(`modifier-group-${errorGroup.id}`, isModal);
  };

  const addProductHandler = async () => {
    if (!product || disable) return;
    setDisable(true);
    await addToWishlist();
    if (!inCart) setShowNotification(true);
    setDisable(false);
  };

  const shareButtonNode = WITH_SHARE_PRODUCT ? (
    <ShareButton
      className="ProductModal__share"
      config={{
        title: `Compartir producto`,
        text: `Comparte este producto con otra persona`,
        url: `${getFullPath()}`
      }}
    />
  ) : null;

  const productActionsNode = () => (
    <section className="ProductMain__actions">
      {isDinners ? (
        <InfoMessage
          message={t.dinersAdvisement}
          className="ProductMain__advisement"
        />
      ) : null}
      <Counter
        className="ProductMain__counter"
        min={1}
        max={isBenefit ? 1 : undefined}
        maxDisabled={!!isBenefit}
        initialValue={selectedProductAmount ?? 1}
        onChange={value => setAmount(value)}
        disabled={disableCounter}
      />
      <AddToCartButton
        className="ProductMain__addToCart"
        form={form}
        onError={errorHandler}
        config={{
          amount,
          comment: ""
        }}
        onFinish={addToCartFinishHandler}
        disabled={disableAddToCart}
      />
    </section>
  );

  const heartNode = () => {
    if (!WITH_WISHLIST) return;
    return (
      <Clickable
        className="ProductMain__wishlist-heart"
        onClick={addProductHandler}
      >
        {icon}
      </Clickable>
    );
  };

  useEffect(() => {
    if (!product || viewProductDetailsLogged.current || !canBeSendAnalytics) {
      return;
    }
    const { storeId: store_id } = snacksStore ?? {};
    const category_name = product.categories?.[0]?.name;
    logViewProductDetails({
      product,
      store_id,
      category_name,
      ...commonParams
    });
    viewProductDetailsLogged.current = true;
  }, [canBeSendAnalytics, commonParams, product, snacksStore]);

  useEffect(() => {
    setAmount(selectedProductAmount ?? 1);
  }, [selectedProductAmount]);

  useEffect(() => {
    if (!productRef.current || !productId) return;
    if (productId !== productRef.current?.productId) {
      setSelectedProduct(undefined);
    }
  }, [productId, setSelectedProduct]);

  useEffect(() => {
    ref.current = selectedProductId;
  }, [selectedProductId]);

  useEffect(() => {
    if (!inCart || !showNotification) return;
    dismissAddToWishlistNotification();
    createAddToWishlistNotification(product, onDelete);
    setShowNotification(false);
  }, [inCart, product, onDelete, showNotification]);

  useOnUnmount(() => setSelectedProduct(undefined));

  return (
    <Styles className={`ProductMain ${className}`} isModal={isModal}>
      <div className="ProductMain__content">
        {!isLoading && product?.questions && form && productId ? (
          <>
            <section className="ProductMain__preview-image">
              <ProductDetailsImage
                images={images}
                name={product.name}
                description={product.description}
                isDiners={isDinners}
              />
            </section>
            <section className="ProductMain__form" id="modifiers">
              <div className="ProductMain__baseInfo-icons">
                {!isLoading && !isError && product && isModal ? (
                  <div className="ProductMain__icons">
                    {heartNode()}
                    {shareButtonNode}
                  </div>
                ) : null}
              </div>
              {!available ? (
                <ProductErrorMessage goToCategories={goToCategories} />
              ) : null}
              {!isModal && WITH_PURCHASE ? productActionsNode() : null}
              {WITH_PURCHASE ? (
                <>
                  <ModifiersForm form={form} disabled={!available} />
                </>
              ) : null}
            </section>
            {isModal ? (
              <section className="ProductMain__wish-button">
                {WITH_WISHLIST && !isLoading && !isError && product ? (
                  <>
                    <AddToWishlistButton
                      className="ProductMain__wishlist-button"
                      product={product}
                      disabled={!isSignedIn}
                    />
                  </>
                ) : null}
                {WITH_SHARE_PRODUCT && !isLoading && !isError && product ? (
                  <>{shareButtonNode}</>
                ) : null}
              </section>
            ) : null}
            {isModal ? productActionsNode() : null}
          </>
        ) : null}
        {(isLoading && !isError) || (!productId && !isError) ? (
          <ProductPlaceholder />
        ) : null}
        {!isLoading && isError && !product ? <ProductError /> : null}
      </div>
    </Styles>
  );
};

export default ProductMain;
