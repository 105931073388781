import styled from "styled-components";

import { ProductErrorStyledProps as Props } from "./ProductError.types";

const ProductErrorStyled = styled.div<Props>`
  grid-area: col;
  position: ${props => (props.isModal ? "absolute" : "static")};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .ProductError {
  }
`;

export default ProductErrorStyled;
